import React from "react";
import General from "./General";

const Italian = () => {
  return (
    <General
      heading="Reincarnation"
      writer="Harsha Satija"
      branch="Meta"
    >
    <p>The breezy cold wind, snow falling steadily on the little German girl &ldquo;Anja&rdquo;.Anja, a 14-year-old girl travelling at her own risk, is fearless about her future. Chasing her dreams, she ran from Germany from her jail, the home of her stepmom. The longest journey she has ever taken in her 14 years of life was to Italy. The Bus Driver Leon, his mom&rsquo;s childhood friend, was her guide to fly in her life. The languages she knew were German and English, though everything in this seems to be seen, felt familiar, which made her think more and more about how her life would be in this new country. Her guide took her to the church as she starts the journey, interestingly she got stuck in Deja Vu, She said to herself &ldquo; why everything seems so familiar, I have some sort of connection with this place, but this is the first time that I have stepped on this land of Romans.&rdquo; Her guide then helped her to find a rented room near the church. The room was small as she couldn't afford to stay in luxury at the beginning of the journey. She unpacked her clothes to set them in the old dusty wooden almirah, but as she opened it her mind went blue. She kept her clothes on the small wooden table and went near the almirah where there was a large book, she was astonished to see the book. As she touched the book it start speaking in some roman words, she screamed &ldquo;Ouch, who are you? I don't understand anything?&rdquo;</p>

<p>Book says &ldquo;Aabra Daabra English&rdquo;, then the book says &ldquo;Hey Anja, you know English Right? Anja says &ldquo;Yes&rdquo; in a very low voice. Anja, Welcome to &ldquo;The world of Italians&rdquo; again. Astonishingly she asked, &ldquo;What? Again...&rdquo; Dear Anja, you belong to Italy, for your last six lives. &ldquo;Is it true?&rdquo; But for the last 14 years, I have been German. Anja, it was unpredictable, you are a woman and you will be a roman forever. &ldquo;Oh okay...&rdquo; Book gasps, &ldquo;Anja, now I am going to tell you what you already know from your past lives.&rdquo; The confused girl says, How is it possible that I already know??? The book shows the magic and there were sparkles in the wind. Anja smiled, &ldquo;Wow, it&rsquo;s amazing.&rdquo; Book says, &ldquo;I am going to tell you everything about us. We are Italians and you are the one who played a major role in influencing our culture in each of our six lives.&rdquo; Anja curiously listens to each word of the book. The book starts with Italian art which has influenced several major movements throughout the centuries and has produced several great artists, including painters, architects and sculptors. Anja, Have you heard of Leonardo Da Vinci? Anja thinking hard... &ldquo;Oh don&rsquo;t you worry, let me tell you a bit about his creations, I am more than a hundred per cent sure you know this elite personality.&rdquo; The portrait of Monalisa, Lady with an Ermine, and Uffizi were some of his known ones. Along with his paintings, he was an engineer, scientist, theorist, sculptor and architect.</p>

<p>Anja interrupted, &ldquo;Wait, I have seen Monalisa's painting in my general knowledge textbook.&rdquo; Book chuckled, &ldquo;It's good to hear that even the German children are taught about the artwork of Italy. This new world is changing. Well, shall I tell you a secret&rdquo; Anja was curious to know, as she always liked surprises and secrets. She nodded happily. Book whispers,&rdquo; Anja it&rsquo;s you the great Leonardo Da Vinci.&rdquo; Anja shocked... She questions, &ldquo;Are you serious? Is it even possible? I think you are kidding. Book says humbly,&rdquo; Yes it is, you may think of yourself as an ordinary German girl but the origin of your soul is old and precious. You will be proud of Italy forever.&rdquo; Anja tries to believe the book. Again book says,&rdquo; whatever skills we had in past lives, remain with us until our soul remains.&rsquo;&rsquo; Anja asks the book, &ldquo;So, I now also have the skills of Leonardo Da Vinci.&rdquo; The book answers her with lots of joy, &ldquo; Yes dear, you not only own the skills of Leonardo Da but also of the other five lives.&rdquo; After, a long pause. Anja, &ldquo;The Italian fashion industry is one of the country's most important manufacturing sectors.&rdquo; Milan is seen as the fashion capital of Italy because many well-known designers are based there and it is the venue for the Italian designer collections. Many of Italy's top fashion designers have boutiques that can be found around the world. Anja asks,&rdquo; Am I also one of the famous fashion designers?&rdquo; Hahaha...(Book laughs at the question)</p>

<p>The book conveys,&rdquo; No Anja, but I am talking about fashion designers as it's predicted by the gods of Italy that you will become a fashion designer in this life.&rdquo; Anja&rsquo;s face cheered up, listening about her future. She took a deep breath and said to the book,&rdquo; You know I came from Germany to become a fashion designer.&rdquo; There is some unusual voice in the room as if something is coming with a great force. Book screaming,&rdquo; Anja runs away, Fast...&rdquo; &ldquo;Why?&rdquo; &ldquo;You are in danger, Anja.&rdquo; Surprisingly Anja asked, &ldquo;How is it possible, why did someone have anger with a 14-year-old German girl?&rdquo; Book shouts, &ldquo; Anja doesn&rsquo;t ask any questions. Runaway at the fourth dimension of speed.&rdquo; Anja opens the room in haste and runs as fast as possible. She kept on running on the never-ending road. She was exhausted and sat under the tree. It was icy cold, she had no clue where to go. She whispered to herself,&rdquo; I am strong.&rdquo; She slept under the tree, and a lot of voices were coming all around. In her dream, she saw the light, a feeling of energy in her blood. It was the arrival of Lord Jupiter. Lord Jupiter smiles at Anja and says,&ldquo; Today I give you all the power, and you will live forever.&rdquo; Anja surprisingly asks,&rdquo; Why Lord?&rdquo; &ldquo;Dear Anja, you have made Italians proud and my devotees now and then ask about you in their prayers. They are sure that you are the one who will do something wonderful for them again.&rdquo; Suddenly the light started dimming and it was completely dark.</p>

<p>She wakes up in surprise, unable to believe what she saw the moment before. She takes the courage to get up and go back to the one who can solve the mystery. She talks with herself,&rdquo; What is the secret behind the book? Why is the book helping me so much for sure God is not in the book.&rdquo; Recalling the way, she ran away back to the long road like a tigress. Flashbacks and flashbacks of different people come to her mind again and again. Stopping her to move forward. She can see &ldquo;A painter painting, along with an architect, a musician, a dancer, a football player and there were noises of cheers, gossips and several sounds.&rdquo; She screamed,&rdquo; STOP STOP ... I said STOP for God's sake.&rdquo; A dog standing next to her observed her and saw her as if something was unusual and started barking at her, then the dog started running... The girl unknowingly without any second thought started following the dog and now she was there where she had to be. She climbed the stairs back to the room where she was talking to the book and what she found there now was nothing but calmness. She started searching for the book. The room felt as if no one had ever come there before. She thought about how she got the book and she remembered about the cupboard and as she unlocked the cupboard she found a half-burnt book. She was so terrified seeing it that she dropped the book on the floor. A trembled voice... &ldquo;Anja, What are you doing? I am already in the worst condition.&rdquo; &ldquo;I am very sorry, who did this to you? Picking up the book.&rdquo; Book says,&rdquo; Anja, don't ask me silly questions, we don't have time.&rdquo; &ldquo;Why don't you have time?&rdquo; &ldquo;Dear Anja, my life is at stake.&rdquo;</p>

<p>&ldquo;How? And you are a book, who will benefit from your life?&rdquo; The book screams,&rdquo; Anja, I am also a human being like you who had been forced to live in the book. The reason for me being here is you.&rdquo; &ldquo;Eh you, why are you blaming me for no reason, I don't even know you.&rdquo; &ldquo;Anja, I love you.&rdquo; &ldquo;Do you think I am silly?&rdquo; &ldquo;No, Wait Anja. I have been your lover for the last six lives and in this life, I was searching for you at every corner of this world.&rdquo; She was just in utter shock and then she asked in the lowest voice,&rdquo; What's your real name?&rdquo; &ldquo; You are asking my name of which life?&rdquo; &ldquo; Of Course of this life.&rdquo; &ldquo;My name is linked to you, common Anja, guess it.&rsquo; &ldquo;Anja is a German name, and you are Italian, how will they be linked?&rdquo; &quot;My Name is Jack, can you see it starts from you.&quot; Anja sighs, &quot; I don't know how to believe in you, at present you are a stranger &quot; &quot; Anja, please don't call me a stranger.&quot; &quot; Do you have any proof that whatever you framed until now is true?&quot; Jack comes in his form as a human being. &quot;Now can you believe that I am Jack?&quot; Anja was completely confused about what was happening at the moment. She thought, there is something fishy with this place. Jack was just lost in her world remembering how they used to do romance and life was going beautifully. &ldquo;Anja, where are you lost?&rdquo;</p>

<p>&ldquo;Jack, I can&rsquo;t imagine you as my partner as you are much older than me.&rdquo; &ldquo;Anja, age is not a barrier when it comes to love and we are successful in loving each other for the last six lives. Why not this life.&rsquo; &ldquo;But Jack, were you always this older than me.&rdquo; &ldquo;No Anja, only this time as you have made some mistakes in your last life, it took 30 years for you to take new life.&rdquo; &ldquo;Jack, you know everything but I don't even have any clue about my past lives.&rdquo; &ldquo;Yes Anja, the reason being in the last life you had an accident and you forgot everything.&rdquo; &ldquo;Jack, it's super hard to believe, I feel as if I am in a dream.&rdquo; He chuckles, &ldquo;That's our life, Anja.&rdquo; &ldquo;Why our? I am not believing you until you give me some valid proof, that is you make me remember everything you are telling.&rdquo; Jack(laughing),&rdquo; Can&rsquo;t you see yourself you are just fourteen and talking like a 30-year lady.&rdquo; &ldquo;Quiet.&rdquo; &ldquo;You think as much as you want. I am stopping the time for you.&rdquo; Anja puts her hands on her head, thinking hard about her life and its solution. &ldquo;Leave me alone Jack.&rdquo; &ldquo;I can&rsquo;t leave without you .&rdquo; &ldquo;You were living without me for years.&rdquo; &ldquo;Every day without you was like a century Anja, there was not a single day when I haven't prayed to Jupiter to meet you and now after years of waiting we are together.&rdquo; &ldquo;I can&rsquo;t listen anymore, if you love me then make me fall in love with you in this life without reference to the past.&rdquo;</p>

<p>&ldquo;Anja, I am no longer a real human being, I am a resident of this book.&rdquo; &ldquo; Oh damn, how you expect a human being to fall in love with you .&rdquo; &ldquo;I am not expecting anything, we are already one and it does not matter for us to do what other lovers do as it's been our last life together, and in the past, we enjoyed it a lot.&rdquo; &ldquo;Jack, stop it. I want a normal life. I came here to chase my dreams and not to live under any imagination. And you are in an imaginary world.&rdquo; &ldquo;Anja, living together for us is as important as rising and setting the sun.&rdquo; &ldquo;Why?&rdquo; &ldquo; Anja doesn't force me to say what I don't want to say.&rdquo; &ldquo; Say .&rdquo; &ldquo; You are Juno, the Roman goddess of love and marriage.&rdquo; &ldquo;How can I believe it ?&rdquo; &ldquo;It's the truth, in this life you are Anja and I am Jack.&rdquo; &ldquo;Jack, you are Lord Jupiter.&rdquo; &ldquo;Yes, we are husband and wife.&rdquo; &ldquo; But you were saying we were together for six lives.&rdquo; &ldquo;Yes, we took birth as humans and after this life, we won't be coming back to the world of humans.&rdquo; &ldquo;Jack, what about the dream I had of Lord Jupiter.&rdquo; &ldquo;It was me who came to your dream to tell you the goal of this life.&rdquo; &ldquo;You are Lord Jupiter, who has immense power than why you are stuck in the book.&rdquo; &ldquo;Anja, God and evil both exist in this mere world. As we were not together, my power decreased.&rdquo; &ldquo; What is the relation of your power with me?&rdquo; &ldquo;Dear, Our love is our power and love exists in the world because of us.&rdquo;</p>

<p>&ldquo;So now you can come back to normal if we come together.&rdquo; &ldquo;Yes.&rdquo; Suddenly, the light gets dimmer, everything starts shaking, Jack starts falling into the book and the devil comes back to take Jack. &ldquo;Anja leave.&rdquo; &ldquo;No, I will not leave.&rdquo; Diavolo comes in laughing. Diavolo, lifting the book, says,&rdquo; Now, I am going to rule the world.&rdquo; Diavolo touches Anja and gives an evil smile. Anja suddenly starts fainting. Jack screams,&rdquo; Anja you can&rsquo;t faint. It's the matter of the lives of us and our people.&rdquo; Diavolo happily says, &ldquo; Now no one can save you, Jupiter, your stupid wife can't even save herself from me and you are thinking to save the world from me.&rdquo; Lord Jupiter starts praying to Diavolo,&rdquo; Please leave my wife and my devotees, you have the problem, with me, kill me not others.&rdquo; &ldquo;Jupiter, do you think I will listen to you?&rdquo; &quot;Juno, you can save the world .&quot; Anja was lying on the floor, a light started blinking at her heart, the young girl started growing. &quot;Diavolo Juno is coming. Run for your life.&quot; Diavolo confidently says, &quot;Jupiter you think I am afraid of Juno. I can finish both of you in seconds but you know I want you guys pleading for your life.&quot; Goddess Juno comes in her form and gets up from the floor. She stands in front of Diavolo. She says, &quot;Leave Jupiter, and take him out of the book.&quot; &quot;Juno, don't talk like a fool, and I don't get how you small guys think you can rule the world. Can you see how stronger I am?&quot;</p>

<p>Juno got the power and she started growing taller and stronger. Diavolo gets shocked seeing her then she takes the Diavolo in her hand. &ldquo;Diavolo, I am giving you the last warning, make everything as fine as before, otherwise you are going to lose your life.&rdquo; &ldquo;Goddess Juno, please don't kill me, remember I am also your devotee.&rdquo; &ldquo;Promise me Diavolo, you will make everything right?&rdquo; &ldquo;Yes, I will.&rdquo; Diavolo starts hurting Jupiter more and more and the lord Jupiter starts screaming. &ldquo;Diavolo what are you doing?&rdquo; &ldquo;I am doing what I should have done before.&rdquo; Juno gets angry and fires Diavolo. Diavolo laughs, &ldquo;I know you will do this and I wanted this as now my mission is accomplished your Lord Jupiter will stay in this book forever.&rdquo; Juno starts crying and Diavolo dies. The Book starts speaking,&rdquo; Juno please don't cry this was written in the destiny. Don't worry about me.&rdquo; &ldquo;Jupiter, I need you. How can you get back?&rdquo; &ldquo;Only Diavolo's son can take me out of this book.&rdquo; &ldquo;Where is he?&rdquo; &ldquo;He lives in the Dolomites. The way to Dolomite is very tough for the Gods, and his mother is much more dangerous than Diavolo and if she knew that you killed him I can't imagine what she would do.&rdquo; &ldquo;Don't worry Jupiter, I will take you out of this anyhow.&rdquo; Juno takes the book in her hand and kisses the book and says &ldquo;I love you Jupiter and I am very sorry.&rdquo; &ldquo; I love you more. Don't be sorry, I know you will figure out the solution to the problem.&rdquo;</p>

<p>Juno Keeps the book safely and flies away to the Dolomites.</p>
    </General>
  );
}

export default Italian;
