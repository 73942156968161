import React from "react";
import General from "./General";

const Greek = () => {
  return (
    <General
      heading="Thicker Than Wine"
      writer="Vishnu Radhakrishnan"
      branch="Chemical"
    >
    <p>It looked as if someone had poured blood into her kantharos[1]. Maybe that was why she took such a liking to wine. Being the princess of Crete, the wine was always made from the first harvested grapes. The reflection of cherry wine on her pupils could only capture an ounce of the fury she felt. It had been 9 years since the death of her adelphos[2], Androgeus. Time did nothing to anguish the suffering one goes through the loss of a loved one. Greek princesses were not absolved from feeling grief.</p>

<p>The Palace of Knossos was where she lay her head every night. But the title of &lsquo;palace&rsquo; did not make it easier for Ariadne to call her house a home. Life was, and always had been tumultuous. Sometimes, when she rested her head on her woolen mattress, when Daedalus[3] was not hacking away at his latest invention, she could hear scratching sounds and grunts from many meters below, though she was never able to confirm if they were just hallucinations or not. Translating to &lsquo;Bull of Minos&rsquo;, the Minotaur knew no rest. Ariadne called her half-brother by his real name, Asterion because regardless of the circumstances, blood is blood. She was among the very few that saw Asterion for who he was; and that was in no way, a monster.</p>

<p>Since he was half-man, half-beast, normal food was not enough; he needed to consume humans for sustenance. Her father decided that 14 innocent Athenians would be the apt sacrifice to avenge the death of Androgeus, but if Ariadne had her way, she would feed Pasiphae[4] and Minos[5] to the beast. She snickered at the irony of a child eating its parents. She largely blamed her parents for robbing her of a normal life; one without the loss of loved ones, Athenian youngins being sacrificial lambs and Gods meddling in their lives. Her hatred was not restricted to mortals and demigods; it extended to Gods. The fact that he was a God did not stop her from cultivating blinding hatred for Poseidon.</p>

<p>Poseidon was the God responsible for cursing her mother to feel lust towards a white bull and hence giving birth to Asterion. Although Poseidon was responsible for the curse, she still finds her father to be the key reason for everything terrible that has happened to her and even more; from permitting Androgeus to go on a voyage to his eventual demise to being the reason her half-brother is locked up in a labyrinth many meters underground. King Minos was seen as a just and wise ruler by many, but even with the guiding hand of her grandfather, Zeus, Minos could do no right in the eyes of his beloved daughter.</p>

<p>&ldquo;Ariadne agapit&iacute;[6], is the food not good?&rdquo;, Efstathia inquired. Efstathia was a Klarotae[7] who had been with the royal family ever since before Ariadne&rsquo;s birth and was a sort of a mother figure for Ariadne. Just because she was a mother figure for her did not mean she was immune to Ariadne&rsquo;s snide tendencies. &ldquo;It is domina[8] for you, Efstathia. Do not make me repeat myself.&rdquo;</p>

<p>She was restricted to being called domina. She could not be called prinkipissa[9] because women were considered properties of their fathers, brothers or husbands. They were never able to hold noble titles. Efstathia&rsquo;s tenure at the palace did not matter in the eyes of Ariadne. To her, it was as trivial as a princess and granddaughter of Zeus talking to a mere slave. &ldquo;Sorry, domina. Did your porridge turn cold?&rdquo;, Efstathia said timidly. For a second, Ariadne wondered why she would ask such a question. Quickly, she realized she had been lost in thought for over 40 minutes.</p>

<p>She had the kantharos with wine she had been gawking at in one hand and a silver spoon in the other. As vulnerable as one is while consuming food, Ariadne&rsquo;s body language was anything but vulnerable. Her body language could not shelter the hostility her mind bore. Her clenched jaw caved to her words. &ldquo;The porridge is mediocre, but it is of no concern. Inform me when the Athenian trireme[10] sounds it&rsquo;s horn. Their arrival was decided to be a fortnight ago.&rdquo;&ldquo;As you wish, mou domina.&rdquo;, Efstathia responded.</p>

<p>As Efstathia retired to her daily chores, Ariadne rose from her dining table with the kantharos in her grip. In the process of getting up, she managed to spill some of the wine onto her right arm, which she swiftly wiped off with a tablecloth. She could faintly hear dimotiki music[11] from ways away as she walked past the windows by the staircase. She made her way to her bedroom on the second floor of the palace. Her room was no stranger to natural light; with windows on every side providing her with sunlight in the morning and soothing winds of the Archipelago at night. She often journaled her thoughts and plans in her rolls of papyrus. As she untied the tie holding the rolls of papyrus together, her words saw the light of day after what seemed to be an eternity. The lampblack ink had all but faded except for a handful of words. The remnants or what could be made of it read &ldquo;...and I know father would love a ride with the ferryman. Charon[12] will be ecstatic to strike up a conversation across River Styx with the son of Zeus&rdquo;. Her hatred towards her own father knew no bounds.</p>

<p>In the midst of being lost in thought, looking at the scraps of papyrus and the words inscribed on them, she heard the screech of a horn; one of an Athenian trireme. She could recognize it from ways away. She heard faint footsteps underneath all the ruckus caused by the arrival of the trireme. &ldquo;Whoever is coming up is barefoot. Must be one of the amath&eacute;s[13]&rdquo;, Ariadne deduced, referring to one of her servants. &ldquo;These scrolls must not fall into the wrong hands&rdquo;, she discerned. She dumped what was left of the scrolls into her sun-illuminated kantharos in haste. &ldquo;The trireme is dropping anchor at present&rdquo;, Efstathia sounded. &ldquo;I will be down in not more than a minute. Now be gone&rdquo;, Ariadne retaliated as she fidgeted with the hem of her flared skirt. Efstathia could ascertain that something was amiss but it is a rule of thumb to never question your master. Her distress went in vain.</p>

<p>The cherry wine in her hand had all but lost its oaky undertones. Its earthy smell now came from the papyrus plant rather than from the tart cherries. The sunlight revealed that the sepia of the papyrus had not altered the bright redness of the wine. The horn sounded in 3 consecutive bursts; indicating docking of the trireme. Due to its proximity, the sound was deafening; deafening enough to cause ripples in her papyrus-wine concoction. The 20 seconds it took for the wine to settle was succeeded by the face of Apollo.</p>

<p>&ldquo;Have&#8230;have I just&#8230; summoned Apollo?&rdquo;, Ariadne uttered in disbelief. It&rsquo;s not every day that the God of prophecy and the sun appears in your kantharos. &ldquo;What is it that you seek, daughter of the honorable Minos?&rdquo; Apollo questioned. Her first instinct was to correct Apollo; neither was her father an honorable man nor did he refer to her by name. She bit her tongue when she realized the opportunity she had in her hands. She did not ask Apollo for answers fearing he might realize that he had been summoned by mistake. &ldquo;I wish to be the Goddess of Wine&rdquo; she replied. A moment of thought from Apollo was followed by a question. &ldquo;Why must I guide you in that conquest?&rdquo; he asked. &ldquo;Oh, I love wine! I&rsquo;ve been drinking it since I was 2. Pardon me, since I was 3. Mit&eacute;ra[14] used to dip psomi[15] in wine when I was the size of an olive. It&rsquo;s color exudes grandeur and royalty&rdquo;. Her eyes were steady and her words not far from concise. Apollo, being the son of Zeus, was inclined to come to the aid of Ariadne. &ldquo;A young sacrifice on the Athenian trireme has the valor to bring an end to the Minotaur&rsquo;s reign of terror. He will be your ticket out of Crete. Accompany this Athenian to Naxos and court my brother, Dionysus[16]. Then, you shall have what you please&rdquo;. &ldquo;14 Athenians have just laid foot on Cretan soil. How is one supposed to ascertain?&rdquo; Ariadne asked. &ldquo;One need not wear a bejeweled crown to be recognized as royal blood&rdquo;.</p>

<p>She glanced over her shoulder lest she get caught. Once she had confirmed she was in the presence of none, she redirected her attention back to the kantharos. Only this time, she saw the reflection of a vengeful princess with a taste for blood. Apollo was gone. She had to make due with what information was given to her. Her next goal - to seduce the mighty Athenian that was prophesied to make away with the head of the Minotaur. Why would Ariadne side with her half-brother&rsquo;s killer, you ask? For someone who saw the world as an opportunist&rsquo;s playground, blood isn&rsquo;t thicker than wine.</p>

<p>Ariadne was a traditionally beautiful woman. She propped her kantharos on her desk as her mind pieced together her next outfit. She picked out her favorite skirt and blouse. The skirt was slightly bulky but it was the nicest one she had. It was flared at the waist, sporting bright yellow and orange stripes that complimented her light blue, low cut blouse. The great ones always played to their strengths. She applied white lead over her chest, neck and face; wrinkleless, white skin was deemed to be attractive. She adjusted her long tresses of hair, smeared her eyelids with charcoal and added a few other final touches to her makeup before she made her way towards the courtyard to greet the Minotaur&rsquo;s next meal.</p>

<p>She made her way down the staircase towards the courtyard where all the 14 Athenians stood in single file. The sun was beginning to tire. Her father was in conversation with one of his advisors. She gauged each and every sacrifice; desperately trying to figure out which one she had to put on a show for so they would fall in love with her. She knew she had to look out for the warrior type; a mention from Apollo was no feat a weakling could boast about. Her eyes fell upon one of the sacrifices; a brute by the looks of it. Handsome, well-built, well-maintained and looked well-fed. He oozed royal blood. He had two indentations on his left forearm, the arm warriors hold their shields with, indicating he had fought frequently enough for the enarmes[17] to alter the shape of his bones. This had to be him. Being royal blood, she was made to stand in the front, facing the newly arrived sacrifices. She then shifted her eyes towards his in hopes that he would catch her staring.</p>

<p>&ldquo;Very well then. I hope you have all shaken your pat&eacute;ra&rsquo;s[18] hand and kissed your mit&eacute;ra&rsquo;s cheek one last time. Fret not! That is not me trying to bring you solace. Sweat makes human flesh too salty and we do not want your sacrifice to be futile.&rdquo; Minos cried with a conniving smile, trying to make his gruff voice reach the furthest Athenian ears from atop the courtyard dais. With his chest high, arms angled and his hands on his hip, his posture stood proud; too proud for one who is about to have the blood of 14 young men and women on his hands. The loss of his own son did not teach him empathy; rather he took to killing the sons and daughters of men and women he has never met. &ldquo;And not long from now, you will be led to the dining hall on the west side of the palace where we will have a feast; a feast worthy of kings. You can go to sleep tonight with your bellies full of wine and psomi knowing that it will be your ultimate meal. See to it that you thank Demeter[19] for everything on your plate. Be kind with your words, you might have to exchange pleasantries with her lest you are going to Tartarus[20]&rdquo; Minos said as he burst into laughter. The tension among the Athenians was palpable. If this was Minos&rsquo; way of easing the tension, it was a distasteful attempt.</p>

<p>King Minos&rsquo; deplorable comments did not deter Ariadne&rsquo;s gaze at the Athenian braveheart. While the distress was evident in the eyes of most, the warrior&rsquo;s eyes were as narrow as the streets of Rethymnon[21], his eyebrows fixed. He still had not caught sight of the beautiful princess staring at him.</p>

<p>&ldquo;Very well then. My subordinates shall direct you to your personal tents in a short while. Make sure you build up an appetite for tonight&rsquo;s supper.&rdquo; Minos announced as he made his way off the top of the dais towards his office, accompanied by the advisor he was previously in conversation with. Once the king had left the vicinity, the braveheart let his eyes wander. He took to absorbing the beautiful island of Crete; the cold breeze, the smell of olive oil. Finally, his eyes landed on Ariadne. They made eye contact for only a second before she redirected her eyes towards the ground. She was anything but shy, but she had to try her hardest to make it look authentic. Though their eyes did not lock for a substantial while, it was plenty for the braveheart. Though Ariadne could not see it, his pupils had widened, his body language loosened. His stone-like exterior had softened; his face had eased enough to show emotion. He felt the gnawing of Eros[22] in the back of his mind, but he remained steadfast. This was not the evening to fall in love.</p>

<p>One of the Klarotae was given the responsibility of leading the sacrifices to their tents where they lay until it was time for supper. She took the lead and gestured the rest to follow. They made their way to the left of Ariadne. She had to get a word in with the braveheart before she lost her opportunity. As the line of Athenians started moving, Ariadne was forced to scurry so as to catch up to her future prey. &ldquo;O royal one!&rdquo; she said softly, slightly out of breath. The braveheart quickly reciprocated to the call with a glance at Ariadne. That was the final bit of confirmation she needed; he was the royal one. &ldquo;And what may I refer to you by?&rdquo; she asks him as they walk shoulder to shoulder towards the tents. &ldquo;Theseus of Athens&rdquo; he responded resolutely. She paused for not more than 5 seconds - in an attempt to pick the perfect words. &ldquo;Theseus, the son of Aegeus[23]? Who would be foolish enough to send the heir to Athens to impending death?&rdquo; she asked, opening the door for him to reveal more of his character. The better she knows him, the better she can make him dance to her tune. &ldquo;I am amongst the sacrifices by choice. Minos has wreaked enough terror in the hearts of my people. It must come to an end&rdquo; Theseus said, in haste.</p>

<p>This one is resolute. She knew these warrior types; tunnel-visioned by their own purpose that they do not take a minute to stop and take a look around, much like her adelphos. She could play him like a fiddle. &ldquo;And how do you intend to kill the beast and still navigate your way through the labyrinth in one piece?&rdquo; Ariadne asked. &ldquo;You are of royal blood. What good does it do for you to know?&rdquo; Theseus asked, in search of answers. &ldquo;We both have the same objective - to escape this island&#8230;alive&rdquo; Ariadne replied. That answer seemed to catch the attention of Theseus. It is not everyday that a royal maiden flutters her eyelashes before you.</p>

<p>Ariadne had almost sold her part. Theseus had taken the bait; all Ariadne had to do was to reel him in. Just as she was going to land the final blow she needed, Theseus stepped in. &ldquo;Here is a proposal,&#8230;Pardon me. What shall I call you?&rdquo;. &ldquo;Ariadne. Ariadne of Crete&rdquo; she replied, tilting her head to the left as she smiled suggestively. &ldquo;Yes. Here is a proposal, Ariadne of Crete. If you help me slay the minotaur and keep all my brethren alive, we shall escape from Crete and court at Athens, bathed in adoration. For not only would I have slain the monster than cannot be slain, I also would have managed to court Minos&rsquo; daughter. Athens shan&rsquo;t bow their heads in shame once more&rdquo;. Ariadne felt a strong urge to strike Theseus in the face; he was more concerned with returning dignity to Athens than marrying her. How could he be so manipulative? Once again, Ariadne bit her tongue. She tried her best to emulate love and glimmer in her eyes. Although not her best work, love had blinded Theseus enough for her poor acting to go unheeded. &ldquo;We are almost at the tents. Meet me at the brink of dawn, a while before the sacrifices are made to go into the labyrinth without fail. I will come to your aid&rdquo; Ariadne added. &ldquo;Very well, Ariadne&rdquo;, Theseus said as Ariadne took her leave.</p>

<p>She was in a rush. She desperately needed the help of Daedalus. The master craftsman could rebuild anything but his broken heart; his son died not too long ago. He took to craftsmanship to stay busy and distracted. His shed was on the east side of the palace premises. Ariadne grabbed two fistfuls of fabric from either end of her skirt and held it high enough for her to free her legs from the lining&rsquo;s chokehold. She made her way through the palace hallways trying to get to the other side of the palace as fast as possible. It took her around two minutes of sprinting to navigate through the curves and bends of the palace to get to Daedalus&rsquo; shed. Knackered and out of breath, she knocked thrice on Daedalus&rsquo; door. &ldquo;Come in Ariadne&rdquo; a vanquished voice sounded. &ldquo;How in the name of Zeus did you know it was me?&rdquo; Ariadne said, surprised. &ldquo;I can smell the wine. How can I help you, Ariadne?&rdquo; he asked in a forced tone. &ldquo;I came here to ask you how you have been&rdquo;, Ariadne responded. &ldquo;Oh. How considerate of you Ariadne. I still miss Icarus dearly. I do not know how&#8230;&rdquo;. Before he could complete his thought, Ariadne chimed in &ldquo;That is oh so terrible indeed, Daedalus. Nevertheless, I need you to make me two things - a pocket dagger and a very long ball of thread. It is a special request from the king himself!&rdquo;. &ldquo;Very well&rdquo; said a defeated Daedalus.</p>

<p>Ariadne eagerly waited for Daedalus to craft the items she had mentioned earlier. She lost track of time. All she knew was that the feast was well underway and the sun had set. Just as Ariadne was about to give into her second yawn, she received the good news she waited oh so long for. &ldquo;There we are, a pocket sized dagger and the ball of string you requested&rdquo;. &ldquo;I thank you, Daedalus. To your health&rdquo; Ariadne said in relief as she stood up to grab the items. &ldquo;Oh and also, give Minos my finest greetings. The fine wine and bread basket he sent was very considerate&rdquo; Daedalus said as Ariadne was halfway out the door. &ldquo;As you wish, Daedalus&rdquo;, Ariadne replied as she closed the door swiftly.</p>

<p>She resumed her expedition. She had over-exerted herself running from one end of the estate to the other. She was too tired to make her way to the dining hall so dinner was out of the conversation. Additionally, she had to wake up early the next day to meet with her puppet, Theseus. She headed straight to her bedroom. She hid the ball of string and the dagger between the folds of one of her skirts from the cupboard. She took a sip of wine from her kantharos that was on her desk, which she spit out immediately. She forgot about the whole papyrus-wine situation in the midst of her weariness. She pushed the drapes that covered her window apart and headed straight to bed.</p>

<p>The lilac sky was only partially covered by the grey clouds. The breeze blew a tuft of hair into Ariadne&rsquo;s mouth. That was nature&rsquo;s way of waking her up. It took a few seconds for Ariadne to come to her senses. She got up in a jolt when she realized what time it was. She sat up straight on her bed, rubbed her eyes and fixed her blouse. She was so tired that she did not even bother changing into her underclothes. She opened up her cupboard looking for the items she stowed away the night before. She hid the dagger and the ball of thread in her waist band. She adjusted her skirt and hair in front of the mirror so she would look presentable. She had to reapply the charcoal eyeshadow since the sweat from all the physical exertion had wiped it off.</p>

<p>She creeped down the stairs to not alarm any of the Klarotae. She maneuvered her way through the hallways still gripped by dawn. It was not long before she made her way to the tents. Most of the sacrifices were awake; their fates had turned them into insomniacs overnight. Theseus was among the ones awake, eagerly waiting for his future bride to reveal herself amidst the ocean of Athenian anxiety. Theseus snapped out of his engrossment as he saw Ariadne. &ldquo;I assume you are here to bring me good news&rdquo;. She nodded yes as she maintained eye contact with Theseus as her hands wandered towards her waistband in search of the ball of thread and dagger. It took her a few attempts to free both the items from the waistband. She held the paraphernalia between both her hands and discretely handed it off to Theseus. &ldquo;This dagger was crafted by the finest builder in all of Hellas[24]. Use this ball of thread to retrace your path through the labyrinth. Tie it at the entrance so you do not get lost. I will be waiting for you at the entrance of the labyrinth. I shall go now. Take care agapi mou[25]&rdquo;. She departed from Theseus in a hurry; she could not be seen talking to the sacrifices.</p>

<p>She made her way back to her bedroom without making too much clamor. The sun was barely awake. Though she had handed off the ball of thread to Theseus, she was still the one pulling the strings. She was only halfway sure of how to achieve her goal. She knew how to get out of Crete but how does a mortal manage to become a God. She had no intention of marrying Dionysus. She wanted to be the only divinity in the realm of the velvet liqueur; whether it be God or Goddess. She needed the counsel of Apollo once again. The absence of sunlight forced her to postpone her conversation with him.</p>

<p>Not so long after the fall of dusk, just as Ariadne on her way towards the entrance of the labyrinth, the two ceremony horns sounded in unison indicating the commencement of the ritual. The sacrifices were being led into the labyrinth. She arrived at the ceremony as the doors of the labyrinth were being cracked open. Theseus was visibly looking around for his maiden. He finally got a glimpse of her. Ariadne and Theseus locked eyes as the line started moving into the labyrinth. Theseus gave her a reassuring nod right before he disappeared into the abyss which the Minotaur called home.</p>

<p>Her love for Theseus was a ruse; her eyes were still focused on her next goal. Now, she had to wait until sunshine drenched the island for her to make her next move. She made her way towards her room to wait. A few hours later, as the sun shone at full power, she knew it was time. She picked up the kantharos from the previous and subjected it to the sunlight once again. She then proceeded to flick the kantharos with her middle finger in hopes that Apollo would reappear. But to no avail, he was not summoned. Maybe she had to try again with a new cup of wine, or maybe with a sacrifice this time.</p>

<p>She tried brainstorming the possible sacrifices she could make. It had to be a meaningful one - she was asking Apollo on how to become a Goddess after all. Wolves and ravens were usually sacrificed to Apollo but no wolves could be found on Crete; only on the mainland. &ldquo;How in the name of Zeus am I supposed to catch a raven?&rdquo; Ariadne thought to herself. And then it clicked! She decided she would make a votive offering to Apollo. All the puzzle pieces fit into place in her mind.</p>

<p>She made her way downstairs and commanded one of the Kloratae to bring her a new cup of cherry wine. Once it was handed to her, she made her way back up to her room and repeated the whole process once more. Expose the kantharos to sunlight, flick it and hope that Apollo&rsquo;s face appears when the drink is settled. Only this time, it did work! Apollo&rsquo;s face reappeared. &ldquo;What is it that you seek, daughter of the honorable Minos?&rdquo; Apollo questioned once again. &ldquo;What does a mortal have to accomplish to gain immortal status without marriage?&rdquo; Ariadne asked. &ldquo;Quite a mighty ask for the daughter of a demigod, is it not?&rdquo; Apollo said as he chuckled. &ldquo;For an answer of such magnitude, you must make an offering of the same magnitude&rdquo; he continued, expecting a response from the maiden. &ldquo;I shall make a votive offering[26] to you, O Apollo! I shall bring you the finest Tympanon[27]; one worthy of the Gods&rdquo;. Ariadne had played her cards right once again, seducing the God of music with an instrument. &ldquo;Very well. You must obtain the blood, flesh and soul of the creations of the big three[28]; one each. You must place these into the signature creation of the God and you shall replace them&rdquo;. Her question was answered. &ldquo;I thank you Apollo&rdquo; she said right as Apollo disappeared once more.</p>

<p>She felt as if she could almost touch her goals. Now all she had to do was to wait for Theseus to slay her half-brother and whisk her away from this god-forsaken island. She bathed herself and changed into a new set of clothes; just as appealing as the previous outfit but not as bulky in worry that it might hinder her during their imminent escape. She needed Theseus to stay enchanted by her spell. She then proceeded to have a heavy breakfast; she expected to wait outside the labyrinth for a long time. She then stepped out of the palace, kerosene lamp in hand; which she imagined to be her last time here as a mere mortal. She absorbed everything around her on her way towards the entrance of the labyrinth. She found a suitable slab of rock she could call home for the next few hours. The sounds from within the labyrinth were barely audible for anyone on the outside. As time went on, her worry grew and grew. &ldquo;What if the Minotaur has vanquished all of the Athenians? What would I do if I could not become a Goddess?&rdquo;. She began pacing as her mind went racing with all these questions. Only time would tell if her biggest worries would come true.</p>

<p>It would not be until dusk that there would be any form of animation from the Minotaur&rsquo;s playground. Just as pitch black engulfed everything around Ariadne, some form of faint scratching and kicking. Maybe this was it, they were alive! She lit her kerosene lamp up and made her way towards the entrance of the labyrinth. She could hear muffled screams and grunts behind the door six inches thick. It took the sweat of 13 Athenians and a demigod to create a crevice big enough for them to squeeze out of the labyrinth. Finally came out the one Ariadne was ever so desperately waiting for - Theseus. They were all covered in mud and sweat but only Theseus had a handful of scars all over his chest and forearms. But he had done it! He conquered the beast that could not be conquered. This was it, this was her chance.</p>

<p>&ldquo;I will tell you all about it on board the trireme. There is no time to be wasted&rdquo; Theseus said, huffing and puffing. Then they began the final step of the plan to escape Crete; getting on board a trireme undetected. The Athenians used Ariadne&rsquo;s knowledge of the palace premises and the kerosene lamp to find their way to the dock. Once the vessel was located, they sought to board it as soon as they could. They all made their way across the boardwalk and boarded the trireme. Illuminated by the moonlight, they navigated through the Archipelago. The rowers rowed at a rapid pace; powered by the adrenaline of escaping a beast and tempted by the idea of freedom. They continued to make their way through the ominous realm of Poseidon; the crashing waves a lullaby to tiring arms. The briny stench from the sea as well as the sweat and tears of all onboard was among Ariadne&rsquo;s least favorite parts of the excursion. The endless sea provided the perfect spectacle to lay her eyes on as she drowned in her own reflections once again. She needed a way to get to Naxos; the island of Dionysus. Triremes were only built for day-long journeys. Neither were they built to withstand the vicious sea at night nor did they carry enough supplies to sustain 15 souls. Ariadne identified that this was the best opportunity for her to lead the vessel towards Naxos. The escapees were forced to seek refuge in a neighboring island until the supplies were restocked and daylight hit their soiled skin.</p>

<p>Theseus, being the natural leader he was, was deep in thought atop the quarterdeck. It did not take him long to snap out of his contemplation and make his way towards Ariadne who stood underneath the masts. &ldquo;What island can we dock at to restock supplies and rest until the sun is out once more?&rdquo; Theseus asked. &ldquo;Naxos! Naxos is the biggest of the Cyclades[29] and is sure to assist us on our journey&rdquo; Ariadne replied, trying to not sound too excited. &ldquo;Very well. I shall observe the katasterismoi[30] and lead us to Naxos&rdquo; Theseus said. &ldquo;Also, before I forget. Untie the barrels of rocks on both sides of the trireme. We cannot have anything hindering us. If you have trouble untying the knots, use the dagger&rdquo; he added as he handed Daedalus&rsquo; masterpiece of a dagger over to her. It was smeared with blood from its handle to its tip. Though she nodded yes to Theseus, she knew that she would keep the dagger unused; it would come handy in the future.</p>

<p>She found a rag cloth that one of the workers had left on the ground. She twisted it one or two times to make sure it was free of moisture and then used it to cover the bloodied dagger. She proceeded to place the sheathed dagger in between her hip and waistband. She then heeded Theseus&rsquo; command and proceeded to untie the barrels of rocks. It was hard work but she knew she did not have to play the part for too long. Theseus ensured that they could have soil underneath their feet as soon as possible. In no less than three hours, they could see land. If Theseus&rsquo; calculations were correct, they would be able to dock at Naxos in around fifteen minutes. The rowers and the to-be-wed couple celebrated alike. The fatigue had set in long ago; the Archipelago was not kind to the rowers. They rowed with all their might, picturing themselves on dry land.</p>

<p>Guided only by the lit torches at the coast of the landmass and sickly sunlight, the vessel made its way towards the dock. They found a suitable place to dock and tied up the trireme. They stretched their legs to reach the boardwalk and all ran ashore. All of them were elated to have escaped Crete alive and well, except one. Ariadne still had a lot to get done. She had manipulated Theseus into thinking she was a damsel in distress and helping her leave Crete. His role in her grand plan was at its final stretch. Now, she had to get rid of him, but how? She had to play the role of a damsel in distress once again, but in front of a different pair of eyes. But she knew she would cross that bridge once she got there. The Athenians under the lead of Theseus made their way onto the landmass in search of friendly locals. Nobody still knew if they were on Naxos yet. It did not take long for Theseus to find an early bird and inquire to him about their whereabouts, which was indeed confirmed to be the island of Naxos. The job was not done; they still needed supplies and a place to rest. Theseus knew he had to find the king&rsquo;s palace; he could use his royal blood as leverage to garner supplies. Theseus and the remaining 13 Athenians all made their way through the streets of Naxos, mudded and sweaty. For an Athenian prince, it was not a great look.</p>

<p>While the Athenians went off to look for means of survival, Ariadne detached from the pack to pursue her own whims. Ancient legend said that Dionysus resided in the luscious forests of Naxos, hence that was her next destination. She knew that if she wanted to summon him, she would have to make an offering. She looked all around her to see if she could locate a suitable sacrifice. She found a grapevine clambering up a mighty tree some distance away from the street she was standing on. She took a substantial amount of the grapevine along with her and proceeded to make her way towards the forest she believed Dionysus resided in. She looked for any symbolism of the God of Wine that she could offer the grapevine in front of. Unbeknownst to her, she was exploring the forests of the village of Glinado[31]. Eventually, lured by the smell of incense from seemingly the middle of nowhere, she could see structures resembling the pillars of a temple through the bushes. She moved the bushes obstructing her view out of the way to confirm her hunch. The temple was made entirely of Naxion marble, the pillar standing at a mighty thirty or so feet. The engravings on the sides of the temple showed grapevines, grapes, orchards, panthers and even bulls; this was the Temple of Dionysus for sure. The avowed priests and priestesses went on with their early morning chores of clearing the premises of leaves and reciting scriptures.</p>

<p>Ariadne wrestled through the bushes on her way to the entrance of the temple. She put on the front of a God-fearing worshiper looking to make a sacrifice in front of the presbyterate[32]. She matched her body language with her facade; back slumping slightly, her head facing down with the grapevine from earlier held in her upward facing palms giving the impression of a meek worshiper. Her soiled, voyage-worn clothes were a supporting act. She could hear the thiasos[33] banging their Tympanon and blowing their aulos[34] in elation; they were probably drunk considering they worship Dionysus. She walked towards the entrance but was halted by one of the elders. She was told to place the offering on a holy dish at the foot of the door and then get on her knees and submit to Dionysus. She followed the advice of the elder and placed the grapevine on the dish at the foot of the door, kneeled down and recited the invocation of Dionysus. &ldquo;I call to Dionysus, great god of the vine, son of thundering Zeus and headstrong Semele. From the East you came, old before the ancients, throughout the elder world were you beloved; in Naxos and Boitia were you celebrated, in temples and in the savage wilderness, the fleet-footed maenads running in your wake. The sweetest, strongest wine is ever your drink; the mind&rsquo;s release, the body&rsquo;s loosening, your gift. O Dionysus; thyrsus-shaker, ivy-crowned god, we see you in the shadows, we see you on the edges, we see you in the haze of ecstasy, where we know the truth of passion, where we find the essence of our being. Dionysus, I call to you!&rdquo; Ariadne said with her eyes and palms closed.</p>

<p>She heard a clamor though she only saw pure black; it sounded like a crash. She took this as a queue to open her eyes once again. Her eyes quickly focused on the throne within the temple. Atop the gold and ivory laden throne sat Dionysus, with a grapevine garland slightly ajar placed atop his head, his thyrsus[35] clenched in his left fist and his large kantharos on the floor with a trail of wine coming from its mouth. A rather effeminate man sat before her, body language inviting an interaction from Ariadne. Dionysus gestured her to come closer to him. As she walked into the temple, the doors closed behind her seemingly without any natural force. She noticed that the thiasos had all but vanished but their instruments were left behind. &ldquo;What brings you to&#8230;.where are we? What brings you to here, daughter of Nimos?&rdquo; Dionysus asked, slurring his words. &ldquo;I have come here in search of help, O great Dionysus. The Minotaur has been slain by an Athenian adversary, the son of King Aegeus. He then took me hostage on his trireme and is now seeking supplies before resuming our journey to Athens where he will assassinate me. You must help me!&rdquo; Ariadne said, looking into his bloodshot eyes. The panic in her voice was apparent; or she intended it to be. Her eyes bulged out, sitting on her knees. &ldquo;I see. Worry not, I will exile this buffoon from Naxos at once&rdquo; Dionysus said. He took a liking towards the maiden. They say being intoxicated boosts your confidence and Dionysus was brimming with it. It was the ideal way to win over the heart of a maiden; to protect her from an antagonist. Dionysus took it upon himself to fulfill his promise at once.</p>

<p>His mind had made up its mind but his body was not ready to cooperate. He attempted to stand up with authority but the light-headedness and lack of balance were not helping. He spread his arms wide to try and stay on two feet. Using his thyrsus for balance, he looked at Ariadne, struggling to piece his words together. &ldquo;You are&#8230;you are rrrravishing. I&#8230;&rdquo; he trailed off, his eyes fixed on the spilled wine and kantharos at his feet, &ldquo;...shall make you the w&#8230;wife of Dionysus&rdquo; he pieced his words together as if he were a five year old. He walked towards the exit looking for one of the elders. He placed two feet at a time on each of the steps, continuously convincing Ariadne that he was indeed a five year old in the body of a God. He walked up to one of the elders watering the ivy plant. He seemingly gave her instructions, possibly to banish the Athenians from the island. The conversation between Dionysus and the elder did not last too long. He was already making his way back to the throne. Once more, the door closed by itself behind him.</p>

<p>Once again, he sat in his throne, clearly the product of overindulgence. &ldquo;The elders request miracles from me as if I am some&#8230;some kind of God&rdquo; he said, with his laughter eventually turning into breathless coughs. &rdquo;Ha! I need me another drink&rdquo; he said as he held his palms open and before Ariadne could blink an eye, the fallen kantharos magically levitated to his hand, full of wine and the spill disappeared. He eventually placed the kantharos on his arm rest to free his right hand. &rdquo;Anyways, I have banished your Athenian kidnapper. You are safe now. When shall&#8230;when shall we have the marriage proceedings, dear&#8230;what is your name again?&rdquo; he asked as he took a sip of his wine, eyes slowly tiring. &ldquo;Ariadne&rdquo; she replied. Her annoyance was a little more apparent than previous times but nothing a drunkard could pick up on.</p>

<p>As the predator watched her prey in the most vulnerable position one could ask for, she knew it was time to make her move. She measured each and every inch of Dionysus, trying to figure out where a strike would deal maximum damage. She put her hand against her waist band, trying to fish out the blood-soaked dagger as she crept closer and closer. &rdquo;As soon as&#8230;possible, O great Dionysus&#8230;I will&#8230;&rdquo; Ariadne said softly, edging closer between every breath, dagger still in her reach. Once she was close enough, she pulled the dagger out and speared Dionysus through his Adam&rsquo;s apple. The strike was mighty enough for the tip of the dagger to emerge from the back of the neck. She had clearly ruptured the carotid artery considering how much blood his neck spewed all over her arms and clothes. She jerked the dagger sideways to ensure the spinal cord had been severed; dealing with a paralyzed God was much easier than one that is not. She pulled the dagger out of Dionysus; she knew what she had to do next.</p>

<p>She took the doubly-bloodsoaked dagger and placed it into the kantharos full of glimmering wine. The blood effortlessly assimilated into the wine. Finally, she had done it. The dagger had been placed into the signature creation of Dionysus. The dagger was responsible for sending a soul to Hades[36], drawing blood from a creation of Poseidon and piercing the flesh of the son of Zeus. She had fulfilled Apollo&rsquo;s conditions. She dropped the dagger onto the ground and picked up the kantharos. It needed both her hands and some elbow grease but she managed to tilt the kantharos and drink a sip of the mixture of holy blood and wine. The body of the crippled Dionysus disappeared into thin air. Ariadne took the vanishing of Dionysus as an invitation to take a seat on his - now, her throne. She picked up the thyrsus and made it her own. The perfect visual to represent everything Ariadne was as a person - sat atop another&rsquo;s throne, soaked in blood, one hand on the kantharos which lay on the armrest and the other on the thyrsus.</p>

<p>As she was just starting to get comfy in her new throne, the doors to the temple cracked open. She had to let go of the kantharos she gripped to shield her eyes from the blinding white light. She rose from her seat and walked towards the doors, not knowing what to expect. She picked up one of the Tympanons that lay on the floor on her way out. She walked through the doors, still trying to figure out where she was. Her vision slowly began to clear up and she could start deducing what she was seeing from in between her fingers. It was a dining table, a large one at that with one seat vacant. She could not discern the faces sat at the table but her smell could pick up the scent of nectar. She made her way to the vacant seat; surely she was used to taking seats in random chairs by now.</p>

<p>&ldquo;How have you managed this feat, Ariadne?&rdquo; chimed a brittle voice from the head of the grand dining table. It was no one but her grandfather, Zeus. &ldquo;I had deduced that Dionysus had overindulged once again and that was the reason he was not at the table on time. Now it seems that he got into some trouble&rdquo; he continued, not caring enough to look up from his ambrosia[37]. Ariadne remained quiet. She was still getting a feel of where she was and who she was around. She knew she had done it. She was at Mount Olympus[38]. &ldquo;I did what had to be done&rdquo; Ariadne said resolutely. &ldquo;Oh, before I forget Apollo, here is the votive offering I promised you. Sorry about the blood&rdquo; she said as she handed over the blood-soaked Tympanon to Apollo as he sat opposite her, gobsmacked. She then proceeded to feast on the ambrosia that lay before her. &ldquo;Now what do you plan on doing with your power, O Goddess of Wine?&rdquo; Plutus[39] asked from the opposite side of the table. &ldquo;To make sure Minos and Pasiphae meet with your father as soon as possible&rdquo; Ariadne replied, hauntingly.</p>

<p> <h3>Glossary </h3>
    <ul>
    <li id="1">[1] Kantharos - Greek cup</li>
    <li id="2">[2] Adelphos - Brother</li>
    <li id="3">[3] Daedalus - Master inventor</li>
    <li id="4">[4] Pasiphae - Mother of Ariadne</li>
    <li id="5">[5] Minos - King of Crete &amp; Father of Ariadne</li>
    <li id="6">[6] agapit&iacute; - dear</li>
    <li id="7">[7] Klarotae - Slaves from Crete</li>
    <li id="8">[8] Domina - Master </li>
    <li id="9">[9] Prinkipissa - Princess </li>
    <li id="10">[10] trireme - Greek ship </li>
    <li id="11">[11] dimotiki music - Ancient Greek music</li>
    <li id="12">[12] Charon - Ferryman of the underworld</li>
    <li id="13">[13] Amath&eacute;s - Fool  </li>
    <li id="14">[14] Mit&eacute;ra - Mother </li>
    <li id="15">[15] Psomi - Bread </li>
    <li id="16">[16] Dionysus - The God of wine </li>
    <li id="17">[17] Enarmes - Leather gripping straps attached to the back of      shields.</li>
    <li id="18">[18] Pat&eacute;ra - Father </li>
    <li id="19">[19] Demeter - Goddess of grain and agriculture </li>
    <li id="20">[20] Tartarus - Underworld/hell </li>
    <li id="21">[21] Rethymnon - City in Crete </li>
    <li id="22">[22] Eros - God of love </li>
    <li id="23">[23] Aegeus - King of Athens </li>
    <li id="24">[24] Hellas - Ancient Greek name for Greece</li>
    <li id="25">[25] Agapi mou - My love </li>
    <li id="26">[26] Votive Offering - An offering made by a mortal once a favor is granted. </li>
    <li id="27">[27] Tympanon - A percussive instrument </li>
    <li id="28">[28] The Big Three - Zeus, Hades and Poseidon </li>
    <li id="29">[29] Cyclades - Island group in the the Archipelago </li>
    <li id="30">[30] Katasterismoi - Placing of the stars/constellations </li>
    <li id="31">[31] Glinado - Village in which the Temple of Dionysus is located. </li>
    <li id="32">[32] Presbyterate - Priests and priestesses </li>
    <li id="33">[33] Thiasos - Worshippers of Dionysus </li>
    <li id="34">[34] Aulos - Air instrument </li>
    <li id="35">[35] Thyrsus - A staff surmounted by a pine cone or by a bunch of vine or ivy leaves with grapes or berries carried by Dionysus </li>
    <li id="36">[36] Hades - God of the underworld </li>
    <li id="37">[37] Ambrosia - Food or drink of the Greek gods </li>
    <li id="38">[38] Mount Olympus - Home of the Greek gods </li>
    <li id="39">[39] Plutus - God of wealth, son of Hades</li>
    </ul>
  </p>
    </General>
  );
}

export default Greek;
