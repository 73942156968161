import React from 'react'

class SquareOne extends React.Component{
  render(){
    return (
      <>
          <h1>ISTE SquareOne Leaderboard</h1>
          <script src="https://sheetdb.io/s/t/0zxahfzd26kud.js"></script>
      </>
    )
  }
}

export default SquareOne