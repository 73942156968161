import Project1 from "./Project1";
import Project2 from "./Project2";
import Project3 from "./Project3";
import Project4 from "./Project4";
import project5 from "./Project5";
import project6 from "./Project6";
import project7 from "./Project7";

const clutch = [Project1, Project2, Project3, Project4, project5, project6, project7];
export default clutch;
