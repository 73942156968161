import Project1 from './Project1';
import Project2 from './Project2';
import Project3 from './Project3';
import Project4 from './Project4';
import Project5 from './Project5';
import Project6 from './Project6';
// import Project6 from "./Project6";
// import Project7 from "./Project7";
// import Project8 from "./Project1";

const crypt = [
    Project1,
    Project2,
    Project3,
    Project4,
    Project5,
    Project6
];
export default crypt;
